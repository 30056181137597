<template>
  <div>
    <!--    <h1>funtoy支付网页</h1>-->
    <div v-if="final == 1">
      <img src="@/assets/images/mycard/success.png" alt="" />
      <h2 style="color: #67c23a">{{ $t("final.success") }}</h2>
      <p>{{ $t("final.tips") }}</p>
    </div>
    <div v-else>
      <img src="@/assets/images/mycard/fail.png" alt="" />
      <h2 style="color: #f56c6c">{{ $t("final.failed") }}</h2>
      <p
        style="text-align: center; color: #535353; margin-top: 20px"
        v-if="msg !== '%s'"
      >
        {{ msg }}
      </p>
    </div>
    <button @click="closeTab" class="go_home">{{ $t("myCard.closeMyCard") }}</button>
  </div>
</template>

<script>
import { setLocalStorage } from "@/utils/stage";

export default {
  name: "final",
  data() {
    return {
      final: this.$route.query.final,
      msg: decodeURIComponent(this.$route.query.msg) || '',
    };
  },
  created() {
    setLocalStorage("pointPrice", 0);
    setLocalStorage("pointNum", 0);
    setLocalStorage("pointExtraNum", 0);
    setLocalStorage("chooseProduct", "");
  },
  methods: {
    closeTab() {
      window.close()
    },
  },
};
</script>

<style scoped>
img {
  width: 80px;
  margin: 100px auto 20px;
}
.go_home {
  margin-top: 50px;
  padding: 5px 10px;
  border-radius: 4px;
  color: #0dc5ff;
  border: 1px solid #0dc5ff;
  cursor: pointer;
  background: transparent;
}
</style>
